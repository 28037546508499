const cardsAdapter = (data) => {
  return {
    title: data.title,
    text: data.text,
  };
};

export default ({ data }) => {
  return {
    title: data.title,
    items: data?.items.map(cardsAdapter),
  };
};
